import React from 'react'
import { Nav, Navbar} from 'react-bootstrap'
import styled from 'styled-components'

const Styles = styled.div`
    .navbar-brand, .navbar-nav .nav-link {
        color: white;

        &:hover {
            color: #bbb;
        }
    }
`;

const NavigationBar = () => (
    <Styles>
        <Navbar variant="light" bg="primary" expand="lg" sticky="top">
            <Navbar.Brand href ="/">Lomond Roads Cycling Club</Navbar.Brand>
            <Navbar.Toggle aria-controls = "basic-navbar-nav" />
            <Navbar.Collapse id = "basic-navbar-nav">
                <Nav className = "ml-auto">
                   <Nav.Item><Nav.Link href ="/">Home</Nav.Link></Nav.Item>
                   <Nav.Item><Nav.Link href ="/aboutUs">About Us</Nav.Link></Nav.Item>
                   <Nav.Link href="/clubRides">Club Rides</Nav.Link>
                   <Nav.Link href="/clubEvents">Club Events</Nav.Link>
                   <Nav.Item><Nav.Link href ="/contactUs">Contact Us</Nav.Link></Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    </Styles>
)

export default NavigationBar;