import React from "react"
import { Jumbotron as Jumbo } from "react-bootstrap"
import styled from "styled-components"
import backgroundPattern from "../images/bkgrd_str.png"

const Style = styled.div`
  .jumbotron {
    background-image: url(${backgroundPattern});
  }
`

const Jumbotron = props => (
  <Style>
    <Jumbo>
      <h1>{props.header}</h1>
      <p>{props.pageSummary}</p>
    </Jumbo>
  </Style>
)

export default Jumbotron
